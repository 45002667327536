import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section className="section bg-light" id="blog">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center">
                <h4 className="title-heading text-uppercase">
                  {t("about_us")}
                </h4>
                <p style={{ fontSize: 18 }} className="title-desc text-muted">
                  {t("about_us_content")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Blog;
