import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const Contact = () => {
  const { t } = useTranslation();

  const formik: any = useFormik({
    initialValues: {
      name: "",
      email: "",
      text: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      text: Yup.string().required("Please Enter Your Text"),
      comments: Yup.string().required("Please Enter Your Comments"),
    }),
    onSubmit: (values: any) => {
      // console.log('newContact', newContact);
      formik.resetForm();
    },
  });
  return (
    <React.Fragment>
      <section className="section bg-white" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="contact-head text-center">
                <h4 className="title-heading text-uppercase">
                  {t("contact_us")}
                </h4>
                {/* <p className="title-desc text-muted mt-3">
                  Find the perfect business template for any type of business
                  size. Browse be websites created for small businesses, such as
                  online service providers.
                </p> */}
              </div>
            </Col>
          </Row>
          <Row className="mt-4 vertical-content">
            <Col lg={12}>
              <Row className="mt-5">
                <Col lg={4}>
                  <div className="contact-info">
                    <div className="mt-3">
                      <h6 className="text-muted">{t("email")}</h6>
                      <p>aubaine.center@gmail.com</p>
                    </div>

                    <div className="mt-4">
                      <h6 className="text-muted">{t("dental_phone")}</h6>
                      <p>
                        <a href="tel:07805550444">07805550444</a>,{" "}
                        <a href="tel:07731130005">07731130005</a>
                      </p>
                    </div>

                    <div className="mt-4">
                      <h6 className="text-muted">{t("clinic_phone")}</h6>
                      <p>
                        <a href="tel:07716111211">07716111211</a>
                      </p>
                    </div>

                    <div className="mt-4">
                      <h6 className="text-muted">{t("center_address")}</h6>
                      <p>
                        {t("address")}
                        <br />
                        {t("aubaine")}
                      </p>
                    </div>

                    <div className="mt-4">
                      <h6 className="text-muted">
                        {t("social_media")} ({t("teeth_department")})
                      </h6>
                      <Link
                        href="https://www.facebook.com/drlaythhussein?mibextid=kFxxJD"
                        color="inherit"
                        sx={{ mx: 1 }}
                        target="_blank"
                      >
                        <FaFacebook size={25} />
                      </Link>
                      <Link
                        href="https://youtube.com/@aubaine.center?si=1XlugKJhW3nzvhUw"
                        color="inherit"
                        sx={{ mx: 1 }}
                        target="_blank"
                      >
                        <FaYoutube size={25} />
                      </Link>
                      <Link
                        href="https://www.tiktok.com/@aubaine.center?_t=8pApxbCSbWn&_r=1"
                        color="inherit"
                        sx={{ mx: 1 }}
                        target="_blank"
                      >
                        <FaTiktok size={25} />
                      </Link>
                      <Link
                        href="https://www.instagram.com/aubaine.center?igsh=MW4wcGthZTlkajBydA=="
                        color="inherit"
                        sx={{ mx: 1 }}
                        target="_blank"
                      >
                        <FaInstagram size={25} />
                      </Link>
                    </div>

                    <div className="mt-4">
                      <h6 className="text-muted">
                        {t("social_media")} ({t("clinic_department")})
                      </h6>
                      <Link
                        href="https://www.facebook.com/profile.php?id=100080754101193&mibextid=kFxxJD"
                        color="inherit"
                        sx={{ mx: 1 }}
                        target="_blank"
                      >
                        <FaFacebook size={25} />
                      </Link>
                      <Link
                        href="https://www.instagram.com/dr.shaimaaclinic?igsh=MXh0Y3RsMzY1dG4zZw=="
                        color="inherit"
                        sx={{ mx: 1 }}
                        target="_blank"
                      >
                        <FaInstagram size={25} />
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="custom-form">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3333.8586560407302!2d44.4492094!3d33.3225105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x155783082bbffaa7%3A0xf8990f0973b97342!2z2KfZiNio2YrZhiDZhNi32Kgg2KfZhNin2LPZhtin2YYg2YjYp9mE2KrYrNmF2YrZhCBBdWJhaW5lIENlbnRlcg!5e0!3m2!1sen!2siq!4v1724693074772!5m2!1sen!2siq"
                      width="100%"
                      height="450"
                      loading="lazy"
                      title="Aubaine Clinic Location"
                    ></iframe>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
