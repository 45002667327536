import team1 from "../../../assets/images/team/د. ليث.png";
import team2 from "../../../assets/images/team/د. غفران.png";
import team3 from "../../../assets/images/team/د. مرتضى.png";
import team4 from "../../../assets/images/team/د. عمر.png";
import team5 from "../../../assets/images/team/د. حسين.png";
import team6 from "../../../assets/images/team/د. انفال.png";
import team7 from "../../../assets/images/team/د. شيماء.png";
import team8 from "../../../assets/images/team/د. زهراء.png";
import team9 from "../../../assets/images/team/د. نور.png";

const data = [
  {
    value: "128+",
    label: "Projects",
  },
  {
    value: "89",
    label: "Clients",
  },
  {
    value: "15842",
    label: "Members",
  },
  {
    value: "125",
    label: "Employee",
  },
];

const teamMembers = [
  {
    imageSrc: team1,
    name: "dr_laith_hussein",
    graduate: "dr_laith_hussein_graduate",
    certificate1: "dr_laith_hussein_specialist",
    certificate2: null,
  },
  {
    imageSrc: team3,
    name: "dr_murtada_qader",
    graduate: "dr_murtada_qader_graduate",
    certificate1: "dr_murtada_qader_specialist",
    certificate2: null,
  },
  {
    imageSrc: team4,
    name: "dr_omar_salah",
    graduate: "dr_omar_salah_graduate",
    certificate1: "dr_omar_salah_specialist",
    certificate2: null,
  },
  {
    imageSrc: team5,
    name: "dr_hussein_muwaffaq",
    graduate: "dr_hussein_muwaffaq_graduate",
    certificate1: "dr_hussein_muwaffaq_specialist",
    certificate2: null,
  },
  {
    imageSrc: team2,
    name: "dr_ghofran_kareem",
    graduate: "dr_ghofran_kareem_graduate",
    certificate1: "dr_ghofran_kareem_specialist",
    certificate2: null,
  },
  {
    imageSrc: team6,
    name: "dr_enfal_al_janabi",
    graduate: "dr_enfal_al_janabi_graduate",
    certificate1: "dr_enfal_al_janabi_specialist",
    certificate2: "dr_enfal_al_janabi_specialist_1",
  },
  {
    imageSrc: team7,
    name: "dr_shaima_hussein",
    graduate: "dr_shaima_hussein_graduate",
    certificate1: "dr_shaima_hussein_specialist",
    certificate2: null,
  },
  {
    imageSrc: team8,
    name: "dr_zahraa_sabah",
    graduate: "dr_zahraa_sabah_graduate",
    certificate1: "dr_zahraa_sabah_specialist",
    certificate2: null,
  },
  {
    imageSrc: team9,
    name: "dr_nour_el_hoda_abbas",
    graduate: "dr_nour_el_hoda_abbas_graduate",
    certificate1: "dr_nour_el_hoda_abbas_specialist",
    certificate2: null,
  },
];
export { data, teamMembers };
