import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { teamMembers } from "./comon/data";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "@mui/material";

const About = (props: any) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="section bg-light" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="about-title mx-auto text-center">
                <h2 className="fw-normal">{t("our_doctors")}</h2>
                {/* <p className="text-muted pt-4">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                  natoque penatibus et magnis dis parturient montes, nascetur
                  ridiculus mus. Donec quam felis.
                </p> */}
              </div>
            </Col>
          </Row>
          {/* <Row className="justify-content-center mt-5" id="counter">
            {(data || []).map((item: any, index: number) => (
              <Col lg={3} key={index}>
                <div
                  className={`text-muted text-center ${
                    index === data.length - 4 ? "" : " about-border-left"
                  } `}
                >
                  <h3 className="counter-value fw-normal">{item.value}</h3>
                  <p>{item.label}</p>
                </div>
              </Col>
            ))}
          </Row> */}
          <Row className="mt-5" style={{ cursor: "pointer" }}>
            {(teamMembers || []).map((member: any, index: number) => (
              <Col lg={3} key={index}>
                <div
                  className="mt-3"
                  onClick={() => {
                    window.location.href = "#portfolio";
                    props.filterData(member.name);
                  }}
                >
                  <img
                    loading="lazy"
                    src={member.imageSrc}
                    alt=""
                    className="img-fluid team-member mx-auto d-block rounded-circle img-thumbnail"
                  />
                  <div className="text-center mt-3">
                    <p className="fw-medium mb-0">{t(member.name)}</p>
                    {member.name === "dr_laith_hussein" ? (
                      <>
                        <Link
                          href="https://www.facebook.com/drlaithhussein?mibextid=kFxxJD"
                          color="inherit"
                          sx={{ mx: 1 }}
                          target="_blank"
                        >
                          <FaFacebook size={25} />
                        </Link>
                        <Link
                          href="https://www.instagram.com/drlaithhussein?igsh=ZGJhemljZTF2dmVt"
                          color="inherit"
                          sx={{ mx: 1 }}
                          target="_blank"
                        >
                          <FaInstagram size={25} />
                        </Link>
                      </>
                    ) : null}
                    <p className="text-muted mb-0">{t(member.graduate)}</p>
                    <p className="text-muted mb-0">{t(member.certificate1)}</p>
                    {member.certificate2 && (
                      <p className="text-muted mb-0">
                        {t(member.certificate2)}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {/* <section className="section bg-cta">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <h3 className="text-center">
                Ready to start your next web project now?
              </h3>
              <p className="cta-desc text-center mt-3">
                When a good idea comes, you know, part of my job is to move it
                around, just see what people think, get people talking about it.
              </p>
              <div className="text-center mt-4">
                <Link to="#" className="btn btn-custom">
                  Get Started
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </React.Fragment>
  );
};

export default About;
