import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { asnanyFactory } from "../api/apiFactory";
import { Box, useMediaQuery } from "@mui/material";

const offersApi = asnanyFactory.get("offers");

const Offers = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [offersData, setOffersData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    offersApi
      .offers()
      .then((res: any) => {
        setLoading(false);
        setOffersData(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }, []);

  return offersData.length > 0 ? (
    <React.Fragment>
      <section className="section bg-light" id="offers">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h4 className="title-heading text-uppercase">{t("offers")}</h4>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            {(offersData || []).map((item: any, index: number) => (
              <Col lg={isSmallScreen ? 12 : 4} key={index}>
                <div className="blog-menu mt-4">
                  <div
                    style={{
                      display: isSmallScreen ? "flex" : "block",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ height: 300 }}
                      src={item.attachment}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div>
                    <h5 className="mt-3">
                      {item.name}
                      {/* <Link to="#" className="blog-title">
                      </Link> */}
                    </h5>
                    {/* <p className="mt-2 text-muted">{item.description}</p>
                    <div className="mt-3">
                      <Link to="#" className="read-btn">
                        Read More <i className="mdi mdi-arrow-right"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  ) : (
    <Box></Box>
  );
};

export default Offers;
