import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { portfolioData } from "./comon/data";
import { useTranslation } from "react-i18next";

const Works = (props: any) => {
  const { t } = useTranslation();

  const [portfolio, setPortfolio] = useState<any>(portfolioData);
  const [categories, setCategories] = useState(props.selectedMember || "all");

  useEffect(() => {
    setCategories(props.selectedMember);
  }, [props.selectedMember]);

  useEffect(() => {
    if (categories === "all") {
      setPortfolio(portfolioData);
    } else {
      const filteredPortfolio = portfolioData.filter((data: any) =>
        data.category.includes(categories)
      );
      setPortfolio(filteredPortfolio);
    }
  }, [categories, props]);

  const filterData = (value: any) => {
    setCategories(value);
  };

  return (
    <React.Fragment>
      <section className="section bg-white pb-0" id="portfolio">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h4 className="title-heading text-uppercase">
                  {t("our_doctors_work")}
                </h4>
                {/* <p className="title-desc text-muted mt-3">
                  Huge collection of elements, rich customization options,
                  flexible layouts, stunning pages and instant results!
                </p> */}
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <div className="text-center">
                <ul
                  className="col container-filter categories-filter list-unstyled mb-0 gap-1 "
                  id="filter"
                >
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_laith_hussein" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_laith_hussein")}
                    >
                      {t("dr_laith_hussein")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_murtada_qader" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_murtada_qader")}
                    >
                      {t("dr_murtada_qader")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_omar_salah" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_omar_salah")}
                    >
                      {t("dr_omar_salah")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_hussein_muwaffaq" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_hussein_muwaffaq")}
                    >
                      {t("dr_hussein_muwaffaq")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_ghofran_kareem" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_ghofran_kareem")}
                    >
                      {t("dr_ghofran_kareem")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_enfal_al_janabi" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_enfal_al_janabi")}
                    >
                      {t("dr_enfal_al_janabi")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_shaima_hussein" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_shaima_hussein")}
                    >
                      {t("dr_shaima_hussein")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_zahraa_sabah" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_zahraa_sabah")}
                    >
                      {t("dr_zahraa_sabah")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#!"
                      className={`${
                        categories === "dr_nour_el_hoda_abbas" ? "active" : ""
                      } categories`}
                      onClick={() => filterData("dr_nour_el_hoda_abbas")}
                    >
                      {t("dr_nour_el_hoda_abbas")}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="container-grid row-cols-lg-4 row-cols-1 mt-5 g-0 projects-wrapper">
            {(portfolio || [])?.map((portfolio: any, index: number) => (
              <Col className="img-max-width" key={index}>
                <div className="item-box">
                  <a
                    className={`cbox-gallary${index} mfp-image`}
                    href="#!"
                    title="Project Name"
                  >
                    <img
                      className="item-container img-fluid profile business"
                      loading="lazy"
                      src={portfolio.imageUrl}
                      alt="1"
                    />
                    <div className="item-mask">
                      <div className="item-caption">
                        <h5 className="text-dark">{portfolio.title}</h5>
                        {/* <p className="text-muted">client: Kay Garland</p> */}
                      </div>
                    </div>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {/* <section className="section" id="client">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className=" text-center">
                <h4 className="title-heading text-uppercase">Testimonial</h4>
                <p className="title-desc text-muted mt-3">
                  We craft digital, graphic and dimensional thinking, to create
                  category leading brand experiences that have meaning and add a
                  value.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-4">
            <Col lg={8}>
              <Carousel id="owl-demo">
                {(testimonialData || []).map((testimonial: any) => {
                  return (
                    <Carousel.Item key={testimonial.id}>
                      <div className="testi-box">
                        <div className="text-center mt-4">
                          <div className="testi-content">
                            <p className="user-review text-center mb-0">
                              {testimonial.review}
                            </p>
                          </div>
                          <div className="mt-4">
                            <img
                              src={testimonial.image}
                              alt=""
                              className="img-fluid rounded-circle testi-user mx-auto d-block"
                            />
                          </div>
                          <div className="img-post text-center">
                            <p className="testi-patients text-muted mb-1 mt-3">
                              {testimonial.designation}
                            </p>
                            <h5 className="testi-client-name">
                              {testimonial.name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section> */}
    </React.Fragment>
  );
};

export default Works;
