import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Index1 = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="bg-home-half" id="home">
        <div className="home-center">
          <div className="home-desc-center">
            <Container>
              <Row>
                <Col lg={9}>
                  <h2 className="home-title mb-4">{t("aubaine")}</h2>
                  <h5 className="home-desc">{t("sub_title")}</h5>
                  <div className="pt-4">
                    <Link
                      to="/appointment/dental"
                      className="btn btn-custom m-2"
                    >
                      <i className="mdi mdi-arrow-right ms-2"></i>
                      {t("dental_clinic_appointment")}
                    </Link>
                    <Link
                      to="/appointment/clinic"
                      className="btn btn-custom m-2"
                    >
                      <i className="mdi mdi-arrow-right ms-2"></i>
                      {t("clinic_clinic_appointment")}
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Index1;
