import {
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, forwardRef, useState } from "react";
import * as React from "react";
import { asnanyFactory } from "../../../api/apiFactory";
import { useTranslation } from "react-i18next";

const dentistApi = asnanyFactory.get("appointment");

interface SelectDentistProps {
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: any;
  error?: boolean;
  helpertext?: string;
  label?: string;
}

const SelectDentist = forwardRef<any, SelectDentistProps>((props, ref) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<{ full_name: string; id: number }[]>(
    []
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dentistApi
      .getDentist()
      .then((res: any) => {
        setOptions(res.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }, []);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      open={open}
      dir="rtl"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderOption={(props, option) => (
        <div dir="rtl" key={option.id}>
          <Typography variant="subtitle2" {...props}>
            {option.full_name}
          </Typography>
        </div>
      )}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => `${option.full_name}`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          helperText={props.helpertext}
          label={props.label || t("doctor")}
          size="small"
          dir="rtl"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default SelectDentist;
