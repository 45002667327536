import laith1 from "../../../assets/images/doctors_work/laith/حالة 1.jpg";
import laith2 from "../../../assets/images/doctors_work/laith/حالة 2.jpg";
import laith3 from "../../../assets/images/doctors_work/laith/حالة 3.jpg";

import murtada1 from "../../../assets/images/doctors_work/murtaza/حالة 1.jpg";
import murtada2 from "../../../assets/images/doctors_work/murtaza/حالة 2.jpg";
import murtada3 from "../../../assets/images/doctors_work/murtaza/حالة 3.jpg";
import murtada4 from "../../../assets/images/doctors_work/murtaza/حالة 4.jpg";
import murtada5 from "../../../assets/images/doctors_work/murtaza/حالة 5.jpg";

import omar1 from "../../../assets/images/doctors_work/omar/حالة 1 - 1.jpg";
import omar2 from "../../../assets/images/doctors_work/omar/حالة 1 - 2.jpg";
import omar3 from "../../../assets/images/doctors_work/omar/حالة 3.jpg";
import omar4 from "../../../assets/images/doctors_work/omar/حالة 4 - 1.jpg";
import omar5 from "../../../assets/images/doctors_work/omar/حالة 4 - 2.jpg";

import hussain1_1 from "../../../assets/images/doctors_work//hussain/حالة 1/1.jpg";
import hussain1_2 from "../../../assets/images/doctors_work//hussain/حالة 1/2.jpg";
import hussain1_3 from "../../../assets/images/doctors_work//hussain/حالة 1/3.jpg";
import hussain2_1 from "../../../assets/images/doctors_work//hussain/حالة 2/1.jpg";
import hussain2_2 from "../../../assets/images/doctors_work//hussain/حالة 2/2.jpg";
import hussain2_3 from "../../../assets/images/doctors_work//hussain/حالة 2/3.jpg";
import hussain3_1 from "../../../assets/images/doctors_work//hussain/حالة 3/1.jpg";
import hussain3_2 from "../../../assets/images/doctors_work//hussain/حالة 3/2.jpg";
import hussain3_3 from "../../../assets/images/doctors_work//hussain/حالة 3/3.jpg";
import hussain4_1 from "../../../assets/images/doctors_work//hussain/حالة 4/1.jpg";
import hussain4_2 from "../../../assets/images/doctors_work//hussain/حالة 4/2.jpg";
import hussain4_3 from "../../../assets/images/doctors_work//hussain/حالة 4/3.jpg";
import hussain4_4 from "../../../assets/images/doctors_work//hussain/حالة 4/4.jpg";

import gufran1 from "../../../assets/images/doctors_work/gufran/حالة 1.jpg";
import gufran2 from "../../../assets/images/doctors_work/gufran/حالة 2.jpg";
import gufran3 from "../../../assets/images/doctors_work/gufran/حالة 3.jpg";
import gufran4 from "../../../assets/images/doctors_work/gufran/حالة 4.jpg";
import gufran5 from "../../../assets/images/doctors_work/gufran/حالة 5.jpg";

import enfal1 from "../../../assets/images/doctors_work//annfall/حالة 1.jpg";
import enfal2 from "../../../assets/images/doctors_work//annfall/حالة 2.jpg";
import enfal3 from "../../../assets/images/doctors_work//annfall/حالة 3.jpg";
import enfal4 from "../../../assets/images/doctors_work//annfall/حالة 4.jpg";

import shaima1 from "../../../assets/images/doctors_work/shimma/حالة 1.jpg";
import shaima2 from "../../../assets/images/doctors_work/shimma/حالة 2.jpg";
import shaima3 from "../../../assets/images/doctors_work/shimma/حالة 3.jpg";
import shaima4 from "../../../assets/images/doctors_work/shimma/حالة 4.jpg";
import shaima5 from "../../../assets/images/doctors_work/shimma/حالة 5.jpg";
import shaima6 from "../../../assets/images/doctors_work/shimma/حالة 6.jpg";

import zahraa1 from "../../../assets/images/doctors_work/zahraa/حالة 1.jpg";
import zahraa2 from "../../../assets/images/doctors_work/zahraa/حالة 2.jpg";
import zahraa3 from "../../../assets/images/doctors_work/zahraa/حالة 3.jpg";
import zahraa4 from "../../../assets/images/doctors_work/zahraa/حالة 4.jpg";
import zahraa5 from "../../../assets/images/doctors_work/zahraa/حالة 5.jpg";

import nour1 from "../../../assets/images/doctors_work/noor/حالة 1.jpg";
import nour2 from "../../../assets/images/doctors_work/noor/حالة 2.jpg";
import nour3 from "../../../assets/images/doctors_work/noor/حالة 3.jpg";
import nour4 from "../../../assets/images/doctors_work/noor/حالة 4.jpg";

import client111 from "../../../assets/images/client/111.jpg";
import client122 from "../../../assets/images/client/122.jpg";
import client133 from "../../../assets/images/client/133.jpg";

const portfolioData = [
  {
    id: 1,
    imageUrl: laith1,
    title: "Dr Laith",
    category: ["dr_laith_hussein"],
    client: "",
  },
  {
    id: 2,
    imageUrl: laith2,
    title: "Dr Laith",
    category: ["dr_laith_hussein"],
    client: "",
  },
  {
    id: 3,
    imageUrl: laith3,
    title: "Dr Laith",
    category: ["dr_laith_hussein"],
    client: "",
  },
  {
    id: 4,
    imageUrl: murtada1,
    title: "Dr Murtada",
    category: ["dr_murtada_qader"],
    client: "",
  },
  {
    id: 5,
    imageUrl: murtada2,
    title: "Dr Murtada",
    category: ["dr_murtada_qader"],
    client: "",
  },
  {
    id: 6,
    imageUrl: murtada3,
    title: "Dr Murtada",
    category: ["dr_murtada_qader"],
    client: "",
  },
  {
    id: 7,
    imageUrl: murtada4,
    title: "Dr Murtada",
    category: ["dr_murtada_qader"],
    client: "",
  },
  {
    id: 8,
    imageUrl: murtada5,
    title: "Dr Murtada",
    category: ["dr_murtada_qader"],
    client: "",
  },
  {
    id: 9,
    imageUrl: omar1,
    title: "Dr Omar",
    category: ["dr_omar_salah"],
    client: "",
  },
  {
    id: 10,
    imageUrl: omar2,
    title: "Dr Omar",
    category: ["dr_omar_salah"],
    client: "",
  },
  {
    id: 11,
    imageUrl: omar3,
    title: "Dr Omar",
    category: ["dr_omar_salah"],
    client: "",
  },
  {
    id: 12,
    imageUrl: omar4,
    title: "Dr Omar",
    category: ["dr_omar_salah"],
    client: "",
  },
  {
    id: 13,
    imageUrl: omar5,
    title: "Dr Omar",
    category: ["dr_omar_salah"],
    client: "",
  },
  {
    id: 14,
    imageUrl: hussain1_1,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 15,
    imageUrl: hussain1_2,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 16,
    imageUrl: hussain1_3,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 17,
    imageUrl: hussain2_1,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 18,
    imageUrl: hussain2_2,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 19,
    imageUrl: hussain2_3,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 20,
    imageUrl: hussain3_1,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 21,
    imageUrl: hussain3_2,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 22,
    imageUrl: hussain3_3,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 23,
    imageUrl: hussain4_1,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 24,
    imageUrl: hussain4_2,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 25,
    imageUrl: hussain4_3,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 26,
    imageUrl: hussain4_4,
    title: "Dr Hussain",
    category: ["dr_hussein_muwaffaq"],
    client: "",
  },
  {
    id: 27,
    imageUrl: gufran1,
    title: "Dr Ghofran",
    category: ["dr_ghofran_kareem"],
    client: "",
  },
  {
    id: 28,
    imageUrl: gufran2,
    title: "Dr Ghofran",
    category: ["dr_ghofran_kareem"],
    client: "",
  },
  {
    id: 29,
    imageUrl: gufran3,
    title: "Dr Ghofran",
    category: ["dr_ghofran_kareem"],
    client: "",
  },
  {
    id: 30,
    imageUrl: gufran4,
    title: "Dr Ghofran",
    category: ["dr_ghofran_kareem"],
    client: "",
  },
  {
    id: 33,
    imageUrl: gufran5,
    title: "Dr Ghofran",
    category: ["dr_ghofran_kareem"],
    client: "",
  },
  {
    id: 34,
    imageUrl: enfal1,
    title: "Dr Enfal",
    category: ["dr_enfal_al_janabi"],
    client: "",
  },
  {
    id: 35,
    imageUrl: enfal2,
    title: "Dr Enfal",
    category: ["dr_enfal_al_janabi"],
    client: "",
  },
  {
    id: 36,
    imageUrl: enfal3,
    title: "Dr Enfal",
    category: ["dr_enfal_al_janabi"],
    client: "",
  },
  {
    id: 37,
    imageUrl: enfal4,
    title: "Dr Enfal",
    category: ["dr_enfal_al_janabi"],
    client: "",
  },
  {
    id: 38,
    imageUrl: shaima1,
    title: "Dr Shaima",
    category: ["dr_shaima_hussein"],
    client: "",
  },
  {
    id: 39,
    imageUrl: shaima2,
    title: "Dr Shaima",
    category: ["dr_shaima_hussein"],
    client: "",
  },
  {
    id: 40,
    imageUrl: shaima3,
    title: "Dr Shaima",
    category: ["dr_shaima_hussein"],
    client: "",
  },
  {
    id: 41,
    imageUrl: shaima4,
    title: "Dr Shaima",
    category: ["dr_shaima_hussein"],
    client: "",
  },
  {
    id: 42,
    imageUrl: shaima5,
    title: "Dr Shaima",
    category: ["dr_shaima_hussein"],
    client: "",
  },
  {
    id: 43,
    imageUrl: shaima6,
    title: "Dr Shaima",
    category: ["dr_shaima_hussein"],
    client: "",
  },
  {
    id: 44,
    imageUrl: zahraa1,
    title: "Dr Zahraa",
    category: ["dr_zahraa_sabah"],
    client: "",
  },
  {
    id: 45,
    imageUrl: zahraa2,
    title: "Dr Zahraa",
    category: ["dr_zahraa_sabah"],
    client: "",
  },
  {
    id: 46,
    imageUrl: zahraa3,
    title: "Dr Zahraa",
    category: ["dr_zahraa_sabah"],
    client: "",
  },
  {
    id: 47,
    imageUrl: zahraa4,
    title: "Dr Zahraa",
    category: ["dr_zahraa_sabah"],
    client: "",
  },
  {
    id: 48,
    imageUrl: zahraa5,
    title: "Dr Zahraa",
    category: ["dr_zahraa_sabah"],
    client: "",
  },
  {
    id: 49,
    imageUrl: nour1,
    title: "Dr Nour El-Hoda",
    category: ["dr_nour_el_hoda_abbas"],
    client: "",
  },
  {
    id: 50,
    imageUrl: nour2,
    title: "Dr Nour El-Hoda",
    category: ["dr_nour_el_hoda_abbas"],
    client: "",
  },
  {
    id: 51,
    imageUrl: nour3,
    title: "Dr Nour El-Hoda",
    category: ["dr_nour_el_hoda_abbas"],
    client: "",
  },
  {
    id: 52,
    imageUrl: nour4,
    title: "Dr Nour El-Hoda",
    category: ["dr_nour_el_hoda_abbas"],
    client: "",
  },
];

const testimonialData = [
  {
    id: 1,
    review:
      "Their separate existence is a myth. For science music sport etc Europe uses the same vocabulary. The languages only differ in their grammar, their pronunciation and their most common words. Everyone realizes why a new common language",
    image: client111,
    designation: "Founder",
    name: "Willimore Wilson",
  },
  {
    id: 2,
    review:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a normal of letters, as opposed to using making it look like readable English.",
    image: client122,
    designation: "Ceo",
    name: "Charley Brown",
  },
  {
    id: 3,
    review:
      "Looking for an event WordPress theme? Look no further—we’ve got pre-built websites for over 20 different types of events: birthday party, wedding, course launch, marketing event, conference, seasonal event.",
    image: client133,
    designation: "Manager",
    name: "Norden Sophie",
  },
];

export { portfolioData, testimonialData };
