import React from "react";
import { featuresData } from "./comon/data";
import { Col, Container, Row } from "react-bootstrap";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Services = ({ isIndex }: any) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <React.Fragment>
      <section className={`section ${isIndex ? "mt-5" : ""}`} id="service">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h4 className="title-heading">{t("services")}</h4>
              </div>
            </Col>
          </Row>

          <Grid container spacing={3} pt={5} sx={{ width: "100%" }}>
            <Grid item xs={12} lg={6}>
              <div>
                <h5 className="mb-5 mx-3">{t("teeth_department")}</h5>
              </div>
              {(featuresData || [])
                .slice(0, 6)
                .map((feature: any, index: number) => (
                  <div key={index} className="features-box mt-2">
                    <div className="d-flex align-items-center">
                      <div className="features-icon text-center flex-shrink-0 mx-3">
                        <i className={feature.icon}></i>
                      </div>
                      <div className="flex-grow-1 ms-4">
                        <h5 className="mt-0 f-19">{t(feature.titleKey)}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              {isSmallScreen && (
                <Divider
                  sx={{ height: "2px", backgroundColor: "black", my: 5 }}
                />
              )}
            </Grid>

            <Grid item xs={12} lg={6}>
              <div>
                <h5 className="mb-5 mx-3">{t("clinic_department")}</h5>
              </div>
              {(featuresData || [])
                .slice(7, 12)
                .map((feature: any, index: number) => (
                  <div key={index} className="features-box mt-2">
                    <div className="d-flex align-items-center">
                      <div className="features-icon text-center flex-shrink-0 mx-3">
                        <i className={feature.icon}></i>
                      </div>
                      <div className="flex-grow-1 ms-4">
                        <h5 className="mt-0 f-19">{t(feature.titleKey)}</h5>
                      </div>
                    </div>
                  </div>
                ))}
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
