import axios from "axios";
const authInterceptor = (config) => {
  config.headers["Content-Type"] = "application/json";
  return config;
};

const api = axios.create({
  baseURL:
    process.env.CLINICS_APP_API || "https://api-clinics.otech.team/",
});

api.interceptors.request.use(authInterceptor);

export default api;
