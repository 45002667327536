import { Box } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/Logo/logo png white.png";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section className="footer bg-dark">
        <Container>
          <Row>
            <Col
              lg={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="footer-menu">
                <h4 className="mb-4 text-uppercase text-white">
                  {t("aubaine")}
                </h4>
                <p> {t("sub_title")}</p>
                {/* <div className="text-white d-flex gap-1">
                  <Link to="#facebook">
                    <i className="mdi mdi-facebook facebook footer_mdi"></i>
                  </Link>
                  <Link to="#twitter">
                    <i className="mdi mdi-twitter twitter footer_mdi"></i>
                  </Link>
                  <Link to="#google">
                    <i className="mdi mdi-google google footer_mdi"></i>
                  </Link>
                </div> */}
              </div>
            </Col>

            {/* <Col lg={2}>
              <div className="footer-menu">
                <h5 className="mb-4 text-uppercase">About</h5>
                <ul className="text-muted list-unstyled">
                  <li>
                    <Link to="#">About Us</Link>
                  </li>
                  <li>
                    <Link to="#">Press</Link>
                  </li>
                  <li>
                    <Link to="#">Job Opening</Link>
                  </li>
                  <li>
                    <Link to="#">Term</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy</Link>
                  </li>
                </ul>
              </div>
            </Col>

            <Col lg={2}>
              <div className="footer-menu">
                <h5 className="mb-4 text-uppercase">Help Center</h5>
                <ul className="text-muted list-unstyled">
                  <li>
                    <Link to="#">Accounting</Link>
                  </li>
                  <li>
                    <Link to="#">Billing</Link>
                  </li>
                  <li>
                    <Link to="#">General Question</Link>
                  </li>
                </ul>
              </div>
            </Col> */}

            {/* <Col lg={4}>
              <div className="footer-menu">
                <h5 className="mb-4 text-uppercase">Contact us</h5>
                <p>
                  The Nam eget dui Etiam rhoncus. Maecenas eget sem quam semper
                  libero.
                </p>
                <span className="footer-support">Support@abc.com</span>
              </div>
            </Col> */}

            <Col
              lg={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Box
                component="img"
                src={logo}
                alt="logo"
                sx={{ width: "200px", height: "200px", objectFit: "cover" }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="text-center mt-5">
                <p className="mb-0">
                  {new Date().getFullYear()} © {t("aubaine")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Footer;
