import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getOperationType() {
    return api.get(
      `/api/v1/public/operation-type-list/${localStorage.getItem(
        "AsnanyTenantId"
      )}`
    );
  },
  getDentist() {
    return api.get(
      `/api/v1/public/dentist-list/${localStorage.getItem("AsnanyTenantId")}`
    );
  },
  getReservation(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(
      `/api/v1/public/reservation/${localStorage.getItem(
        "AsnanyTenantId"
      )}?${params}`
    );
  },
  create(data) {
    return api.post(`/api/v1/public/reserve_appointment`, data);
  },
};
