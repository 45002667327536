const featuresData = [
  {
    icon: "uil uil-cloud-hail",
    titleKey: "teeth_service_1",
  },
  {
    icon: "uil uil-dropbox",
    titleKey: "teeth_service_2",
  },
  {
    icon: "uil uil-focus-target",
    titleKey: "teeth_service_3",
  },
  {
    icon: "uil uil-fire",
    titleKey: "teeth_service_4",
  },
  {
    icon: "uil uil-game-structure",
    titleKey: "teeth_service_5",
  },
  {
    icon: "uil uil-flower",
    titleKey: "teeth_service_6",
  },
  {
    icon: "uil uil-flower",
    titleKey: "teeth_service_7",
  },
  {
    icon: "uil uil-flower",
    titleKey: "clinic_service_1",
  },
  {
    icon: "uil uil-flower",
    titleKey: "clinic_service_2",
  },
  {
    icon: "uil uil-flower",
    titleKey: "clinic_service_3",
  },
  {
    icon: "uil uil-flower",
    titleKey: "clinic_service_4",
  },
  {
    icon: "uil uil-flower",
    titleKey: "clinic_service_5",
  },
];

export default featuresData;
