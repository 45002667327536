import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getClinicsOperationType() {
    return api.get(
      `/api/v1/public/operation-type-list/${localStorage.getItem(
        "ClinicTenantId"
      )}`
    );
  },
  getClinicsReservation(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(
      `/api/v1/public/reservation/${localStorage.getItem(
        "ClinicTenantId"
      )}?${params}`
    );
  },
  createClinics(data) {
    return api.post(`/api/v1/public/reserve_appointment`, data);
  },
};
