import {
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, forwardRef, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { clinicFactory } from "../../../clinics_api/apiFactory";

const operationTypeApi = clinicFactory.get("appointment");

interface SelectOperationTypeProps {
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: any;
  error?: boolean;
  helpertext?: string;
  label?: string;
}

const SelectClinicsOperationType = forwardRef<any, SelectOperationTypeProps>(
  (props, ref) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<{ name: string; id: number }[]>([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      operationTypeApi
        .getClinicsOperationType()
        .then((res: any) => {
          setOptions(res.data.data);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }, []);

    return (
      <Autocomplete
        {...props}
        ref={ref}
        open={open}
        dir="rtl"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderOption={(props, option) => (
          <div dir="rtl" key={option.id}>
            <Typography variant="subtitle2" {...props}>
              {option.name}
            </Typography>
          </div>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => `${option.name}`}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            helperText={props.helpertext}
            label={props.label || t("select_service")}
            size="small"
            dir="rtl"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default SelectClinicsOperationType;
