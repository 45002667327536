import Appointment from "../components/appointment";
import Index1 from "../pages/index1";

interface RouteProps {
  path: string;
  component?: any;
  exact?: boolean;
  isIndex?: boolean;
}

const Routes: Array<RouteProps> = [
  { path: "/", component: <Index1 /> },
  { path: "/appointment/:clinic_type", component: <Appointment /> },
];

export default Routes;
