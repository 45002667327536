import appointment from "./endpoints/appointment";
import offers from "./endpoints/offers";

const repositories = {
  appointment,
  offers,
};

export const asnanyFactory = {
  get: (name) => repositories[name],
};
