import axios from "axios";
const authInterceptor = (config) => {
  config.headers["Content-Type"] = "application/json";
  return config;
};

console.log(process.env);

const api = axios.create({
  baseURL:
    process.env.ASNANY_REACT_APP_API || "https://api-asnany-test.otech.team/",
});

api.interceptors.request.use(authInterceptor);

export default api;
